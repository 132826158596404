import jwt from '@/auth/jwt/useJwt';

// const PATH = 'contracts'

export function contractList(condition) {
  return jwt.axiosIns.get('/contracts', {
    params: {
      ...condition,
    },
  });
}

export function contractDetail(id) {
  return jwt.axiosIns.get(`/contracts/${id}`);
}

export function contractChangeLogs(id, condition) {
  return jwt.axiosIns.get(`/contracts/${id}/change-logs`, {
    params: {
      ...condition,
    },
  });
}

export function contractOtherContract(id, condition) {
  return jwt.axiosIns.get(`/contracts/${id}/other-contracts`, {
    params: {
      ...condition,
    },
  });
}

export function contractHistoryAction(id, condition) {
  return jwt.axiosIns.get(`/contracts/${id}/actions`, {
    params: {
      ...condition,
    },
  });
}

export function contractHistoryCalls(id, condition) {
  return jwt.axiosIns.get(`/contracts/${id}/calls`, {
    params: {
      ...condition,
    },
  });
}

export function contractUpdateResult(id, data) {
  return jwt.axiosIns.post(`/contracts/${id}/update-result`, data);
}

export function contractCustomerContact(id) {
  return jwt.axiosIns.get(`/contracts/${id}/customer-contacts`);
}

export function contractUpdateCustomerContact(id, data) {
  return jwt.axiosIns.post(`/contracts/${id}/customer-contacts`, data);
}

export function contractExportFileWithId(value) {
  return jwt.axiosIns.get(`contracts/export/${value}`);
}

export function contractExportFileFullWithId(value) {
  return jwt.axiosIns.get(`contracts/export-full/${value}`);
}

export function nextContract(id, status, code = null) {
  return jwt.axiosIns.get(`/contracts/${id}/next`, {
    params: {
      status,
      action_code: code,
    },
  });
}

export function contractDocuments(id) {
  return jwt.axiosIns.get(`/contracts/${id}/documents`);
}

export function contractFieldDocuments(id) {
  return jwt.axiosIns.get(`/contracts/${id}/documents/for-field`);
}

export function contractReloadDocuments(id) {
  return jwt.axiosIns.post(`/contracts/${id}/documents/reload`);
}
