import { ref, watch, computed } from '@vue/composition-api';
import { useRouter } from '@core/utils/utils';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { formatWithTimeZone } from '@/common/utils';
import { getFilesRedoImported } from '@/common/localStorage';
import { importListError } from '@/apis/apiImport';

export default function useUsersList() {
  const { route } = useRouter();
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'row', label: 'Dòng', sortable: true },
    { key: 'col', label: 'Cột', sortable: true },
    { key: 'exception', label: 'Chi tiết lỗi', sortable: true },
    { key: 'created', label: 'Ngày tạo', sortable: true },
    { key: 'updated', label: 'Ngày cập nhật', sortable: true, align: 'center' },
  ];
  const perPage = ref(10);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const typeFilter = ref(null);
  const statusFilter = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  // const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const id = ref(null);

  id.value = route.value.params.id;
  // const roleFilter = ref(null);
  // const planFilter = ref(null);
  // const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  const allowRedoImported = (list, idd) => {
    if (list && list.length > 0) {
      // const theFileIndex = _.findKey(list, { id });
      // if (theFileIndex) {
      //   return false;
      // }
    }
    return true;
  };

  const preProcessData = (data) =>
    // concat arr exception
    data.map((item) => ({
      ...item,
      exception: Array.isArray(item.exception) ? item.exception.join('\r\n') : item.exception,
    }));
  watch([currentPage, perPage, statusFilter, typeFilter, startDate, endDate], () => {
    refetchData();
  });

  const fetchContracts = (ctx, callback) => {
    importListError(id.value, {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: statusFilter.value,
      type: typeFilter.value,
      startDate: startDate.value,
      endDate: endDate.value,
    })
      .then((response) => {
        const { data, meta } = response.data;
        const dataFormatted = preProcessData(data);

        callback(dataFormatted);
        totalContract.value = meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Xảy ra lỗi',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Tải danh sách đối tác thất bại',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (value) => {
    if (value === 'PROCESSING') return 'warning';
    if (value === 'LATE' || value === 'BAD') return 'error';
    if (value === 'PAID') return 'success';
    if (value === 'COLLECTION') return 'secondary';
    return 'primary';
  };

  const statusOptions = [
    { label: 'Mới', value: 0 },
    { label: 'Đang xử lý', value: 1 },
    { label: 'Thu nợ', value: 3 },
    { label: 'Trễ', value: 4 },
    { label: 'Nợ xấu', value: 5 },
    { label: 'Từ chối', value: 6 },
  ];

  const typeOptions = [
    { label: 'Khoản ứng', value: 0 },
    { label: 'Lead', value: 2 },
  ];
  return {
    fetchContracts,
    tableColumns,
    perPage,
    currentPage,
    sortBy,
    isSortDirDesc,
    totalContract,
    dataMeta,
    perPageOptions,
    refUserListTable,
    statusOptions,
    typeOptions,
    statusFilter,
    typeFilter,
    resolveUserStatusVariant,
    refetchData,
    id,
  };
}
