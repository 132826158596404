import { importList } from '@/apis/apiImport';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchImports(ctx, condition) {
      return new Promise((resolve, reject) => {
        importList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
