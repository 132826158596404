import jwt from '@/auth/jwt/useJwt';

export function partnerUpdateAllowImport({ name, allowImport }, id) {
  return jwt.axiosIns.put(`/partners/${id}`, {
    name,
    allowImport,
  });
}

export function partnerCreate(data) {
  return jwt.axiosIns.post('/partners', data);
}

export function partnerUpdate(id, data) {
  return jwt.axiosIns.put(`/partners/${id}`, data);
}

export function partnerList(condition) {
  return jwt.axiosIns.get('/partners', {
    params: {
      ...condition,
    },
  });
}

export function partnerRemove(id) {
  return jwt.axiosIns.delete(`/partners/${id}`);
}

export function showPartner(id) {
  return jwt.axiosIns.get(`/partners/${id}`);
}
