<template>
  <div>
    <v-select :value="value" :options="options" v-bind="$attrs" v-on="$listeners" />
    <select :name="name" :value="value" class="d-none">
      <option
        v-for="(item, index) in options"
        :key="index"
        :value="item.value ? item.value : item.text ? item.text : item"
      >
        {{ item.text ? item.text : item.value ? item.value : item }}
      </option>
    </select>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'BaseSelect',
  components: {
    vSelect,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    value: {
      type: [Number, String],
      default: undefined,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
