import jwt from '@/auth/jwt/useJwt';

export function importUpdateAllowImport({ name, allowImport }, id) {
  return jwt.axiosIns.put(`/imports/${id}`, {
    name,
    allowImport,
  });
}

export function importCreate(data) {
  return jwt.axiosIns.post('/imports', data);
}

export function importUpdate(id, data) {
  return jwt.axiosIns.put(`/imports/${id}`, data);
}

export function importList(condition) {
  return jwt.axiosIns.get('/imports', {
    params: {
      ...condition,
    },
  });
}

export function importListError(id, condition) {
  return jwt.axiosIns.get(`/imports/${id}/errors`, {
    params: {
      ...condition,
    },
  });
}

export function importRedo(id) {
  return jwt.axiosIns.get(`/imports/${id}/retry`, {});
}

export function importRemove(id) {
  return jwt.axiosIns.delete(`/imports/${id}`);
}

export function showimport(id) {
  return jwt.axiosIns.get(`/imports/${id}`);
}

export function importUpload(file, id, campaignId) {
  const data = new FormData();
  data.append('partnerId', id);
  data.append('importFile', file);
  data.append('campaignId', campaignId);
  return jwt.axiosIns.post('/imports', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function uploadActions(partnerId, file) {
  const data = new FormData();
  data.append('partnerId', partnerId);
  data.append('actions', file);

  return jwt.axiosIns.post('imports/actions', data);
}

export function uploadSkips(partnerId, file) {
  const data = new FormData();
  data.append('partnerId', partnerId);
  data.append('skips', file);

  return jwt.axiosIns.post('imports/skips', data);
}
