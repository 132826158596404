<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="9" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <b-row align-v="end">
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :to="{ name: constRouter.IMPORT.name }"
                >
                  <span class="text-nowrap">Quay lại</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :filter="filter"
        :items="fetchContracts"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: name -->
        <template #cell(exception)="data">
          <span style="width: 50%">
            {{ data.item.exception }}
          </span>
        </template>

        <template #cell(success)="data">
          <span v-if="data.item.success >= data.item.total">
            {{ data.item.success }}
          </span>
          <span v-else-if="data.item.success < data.item.total" class="text-success">
            {{ data.item.success }}
          </span>
        </template>

        <template #cell(failed)="data">
          <div class="text-center">
            <template v-if="data.item.failed">
              <b-button
                :id="`detail-error-${data.item.id}`"
                variant="flat-success"
                :disabled="data.item.exportFileLoading"
                class="text-danger"
                :to="{ name: constRouter.IMPORT_VIEW.name, params: { id: data.item.id } }"
              >
                {{ data.item.failed }}
              </b-button>
              <b-tooltip title="Nhấp để xem chi tiết" :target="`detail-error-${data.item.id}`" />
            </template>
            <span v-else-if="data.item.failed === 0">
              {{ data.item.failed }}
            </span>
          </div>
        </template>
        <!-- Column: process -->
        <template #cell(process)="data">
          <feather-icon
            v-if="data.item.failed"
            icon="XIcon"
            size="16"
            class="align-middle text-danger"
          />
          <feather-icon
            v-else-if="data.item.failed === 0"
            icon="CheckIcon"
            size="16"
            class="align-middle text-success"
          />
        </template>
        <!-- Column: active -->
        <template #cell(active)="data">
          <b-button
            v-if="data.item.allow_import === 1 || data.item.allow_import === true"
            v-b-modal.modal-primary
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            @click.stop="showActiveDialog(data.item)"
          >
            <span class="text-nowrap">Huỷ kích hoạt</span>
          </b-button>
          <b-button
            v-else
            v-b-modal.modal-primary
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            @click.stop="showActiveDialog(data.item)"
          >
            <span class="text-nowrap">Kích hoạt</span>
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            variant="flat-success"
            class="btn-icon rounded-circle"
            :disabled="data.item.exportFileLoading"
            @click.stop="exportFile(data.item)"
          >
            <b-spinner v-if="data.item.exportFileLoading" small />
            <feather-icon
              v-else
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="DownloadIcon"
              size="16"
            />
          </b-button>
          <b-tooltip title="Xuất file odoo" :target="`invoice-row-${data.item.id}-preview-icon`" />

          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              :disabled="data.item.exportFileFullLoading"
              @click.stop="exportFileFull(data.item)"
            >
              <b-spinner v-if="data.item.exportFileFullLoading" small />
              <feather-icon v-else icon="DownloadIcon" />
              <span class="align-middle ml-50">Xuất file full</span>
            </b-dropdown-item>
            <b-dropdown-item @click.stop="showRemoveItemDialog(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Xoá</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardText,
  BTooltip,
  BFormFile,
  BFormGroup,
  BContainer,
  BSpinner,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { numberWithCommas } from '@core/utils/utils';
import Ripple from 'vue-ripple-directive';
import { partnerList, showPartner } from '@/apis/apiPartner';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';
import { getFilesRedoImported, getPerPageToken } from '@/common/localStorage';
import { formatWithTimeZone } from '@/common/utils';
import { importList, importUpload } from '@/apis/apiImport';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { contractExportFileFullWithId, contractExportFileWithId } from '@/apis/apiContract';
import useContractList from './useImportListError';
import contractStoreModule from '../partnerStoreModule';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormInput,
    BFormFile,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BCardText,
    BaseSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  setup(props, { root }) {
    const USER_APP_STORE_MODULE_NAME = 'app-customer2';
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, contractStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });
    const myHandler = (val) => {
      root.$router.push({ name: constRouter.PARTNER_VIEW.name, params: { id: val.id } });
    };
    const isAddNewUserSidebarActive = ref(false);

    const {
      id,
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      // UI
      resolveUserStatusVariant,
      // filter
      statusFilter,
      typeFilter,
      statusOptions,
      typeOptions,
    } = useContractList();

    return {
      id,
      // Sidebar
      isAddNewUserSidebarActive,
      refUserListTable,
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      avatarText,
      statusOptions,
      typeOptions,
      typeFilter,
      statusFilter,
      // UI
      resolveUserStatusVariant,

      numberWithCommas,
      myHandler,
    };
  },
  data: () => ({
    constRouter,
    filter: null,
    activeModalTitle: '',
    activeModalContent: '',
    activePartner: null,

    // dialog
    removeDialog: false,

    files: [],
    list: null,
    theLastFileImport: undefined,
    serverItemsLength: 0,
    options: {
      page: 1,
      itemsPerPage: 15,
    },
    loading: false,
    isLoadedData: false,

    // upload file
    listFileID: [],
    callingAPI: false,
    fileLoadingBtn: false,
    // removeDialog: false,
    rmItems: [],

    // template list
    partnersValue: undefined,

    // dialog using to move export view
    dialogMove2ExportView: false,

    // Recursive
    recursive: true,
  }),

  mounted() {
    // start loading
    this.loading = true;
    this.isLoadedData = true;
    this.partnersLoading = true;

    this.options.itemsPerPage = getPerPageToken();
  },
  beforeDestroy() {
    this.recursive = false;
  },
  methods: {
    exportFile(item) {
      // turn on loading
      item.exportFileLoading = true;
      // call API
      contractExportFileWithId(item.id)
        .then((res) => {
          // stop loading
          item.exportFileLoading = false;
          // open new tab for download
          window.open(res.data.url, '_blank');
          // show Toast
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Export {resource}', {
                resource: this.$t('file'),
              }),
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: this.$t('Export {resource} successfully!', {
                resource: this.$t('file'),
              }),
            },
          });
        })
        .catch((err) => {
          // stop loading
          item.exportFileLoading = false;
          // show error
          if (err?.data?.message) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Export {resource}', {
                  resource: this.$t('file'),
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: this.$t(err.data.message),
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Export {resource}', {
                  resource: this.$t('file'),
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: this.$t('Export {resource} failed!', {
                  resource: this.$t('file'),
                }),
              },
            });
          }
        });
    },
    exportFileFull(item) {
      // turn on loading
      item.exportFileFullLoading = true;
      // call API
      contractExportFileFullWithId(item.id)
        .then((res) => {
          // stop loading
          item.exportFileFullLoading = false;
          // open new tab for download
          window.open(res.data.url, '_blank');
          // show Toast
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Export {resource}', {
                resource: this.$t('file'),
              }),
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: this.$t('Export {resource} successfully!', {
                resource: this.$t('file'),
              }),
            },
          });
        })
        .catch((err) => {
          // stop loading
          item.exportFileFullLoading = false;
          // show error
          if (err?.data?.message) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Export {resource}', {
                  resource: this.$t('file'),
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: this.$t(err.data.message),
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('Export {resource}', {
                  resource: this.$t('file'),
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: this.$t('Export {resource} failed!', {
                  resource: this.$t('file'),
                }),
              },
            });
          }
        });
    },

    redoUploadFile(_item) {
      const item = _item;
      // turn on loading
      item.redoUploadFileLoading = true;
      // check allow redo upload
      const allowRedoImported = this.allowRedoImported(getFilesRedoImported(), _item.id);
      if (allowRedoImported) {
        // call API
        // uploadRedo(item.id)
        //   .then(() => {
        //     // stop loading
        //     item.redoUploadFileLoading = false;
        //     // disabled button
        //     item.redoUploadFileDisable = true;
        //     // show Toast
        //     this.successNotification({
        //       message: this.$t('Re-Import {resource} successfully!', {
        //         resource: this.$t('file'),
        //       }),
        //     });
        //     // store to localStorage
        //     addFileRedoImported(item.id, false_item);
        //   })
        //   .catch((err) => {
        //     // stop loading
        //     item.redoUploadFileLoading = false;
        //     // show error
        //     if (err?.data?.message) {
        //       this.errorNotification({
        //         message: this.$t(err.data.message),
        //       });
        //     } else {
        //       this.errorNotification({
        //         message: this.$t('Re-Import {resource} failed!', {
        //           resource: this.$t('file'),
        //         }),
        //       });
        //     }
        //   });
      } else {
        // stop loading
        item.redoUploadFileLoading = false;
        item.redoUploadFileDisable = true;
      }
    },
    allowRedoImported(list, id) {
      if (list && list.length > 0) {
        // const theFileIndex = _.findKey(list, { id });
        // if (theFileIndex) {
        //   return false;
        // }
      }
      return true;
    },
    showRemoveItemDialog(item) {
      // show dialog
      this.removeDialog = true;

      // store item will remove
      // this.rmItems.push(item);
      this.rmItems = [item];
    },

    showChooseFileView() {
      const importPartnerId = this.$route.query['import-partner-id'];
      if (importPartnerId) {
        this.partnersValue = +importPartnerId;
        if (this.showFileChooserDialog) {
          // this.$refs.fileUpload.$refs.input.$refs.input.click();
        }
      }
    },

    colorCircular(number) {
      if (number > 70) {
        return 'success';
      }
      if (number > 35) {
        return 'warning';
      }
      return 'error';
    },

    changeLoadData(status) {
      this.isLoadedData = status;
    },

    removeItem() {
      // turn of dialog
      this.removeDialog = false;

      const newItems = this.list;
      // remove item from list
      this.rmItems.forEach((rmItem) => {
        // find index of remove item
        // const indexOfRmItem = _.findKey(this.list, { id: rmItem.id });
        // remove
        // newItems.splice(indexOfRmItem, 1);
        // call api
        // UploadAPI.remove(rmItem.id)
        //   .then(() => {
        //     this.successNotification({
        //       message: this.$t('Delete {resource} successfully!', {
        //         resource: this.$t('file'),
        //       }),
        //     });
        //   })
        //   .catch((err) => {
        //     this.serverItemsLength += 1;
        //     this.errorNotification({
        //       message: this.$t(err?.data?.message),
        //     });
        //     // add the remove item into list again
        //     newItems.splice(indexOfRmItem, 0, rmItem);
        //   });
      });

      // set data
      if (this.list !== newItems) {
        this.list = newItems;
        this.serverItemsLength -= 1;
      }
      // clear rmItems
      this.rmItems = [];
    },

    // interval realtime
    // loop list fileId
    // call api with id
    // success
    //  check total = success + failed
    //    if equal => remove id in list fileId
    //    if not => continue
    // replace item to list data
    async processInterval() {
      this.callingAPI = true;
      // count length
      const length = Math.min(this.listFileID.length, 3);
      const tempp = [];
      for (let index = 0; index < length; index += 1) {
        const id = this.listFileID[index];
        tempp.push(showPartner(id));
      }
      const allResponses = await Promise.all(tempp).then();
      const isContinueRecursive = this.processResponse(allResponses, length);

      if (isContinueRecursive && this.recursive) {
        await this.timeout(5000);
        await this.processInterval();
      } else {
        // stop, set callingAPI = false, for the next upload file
        this.callingAPI = false;
      }
    },

    processResponse(allResponses, length) {
      for (let index = 0; index < length; index += 1) {
        const id = this.listFileID[index];
        const res = allResponses[index];
        const { total, success, failed } = this.preProcessAData(res.data?.data);
        // replace { total, success, failed } of item to list data
        const locFileInList = +this.list.findIndex(this.list, { id });
        const theFile = this.list[locFileInList];

        theFile.total = total;
        theFile.success = success;
        theFile.failed = failed;
        theFile.process = Math.ceil(((success + failed) / total) * 100);

        if (success + failed >= total) {
          // remove id in listFileId
          // this.listFileID = _.remove(this.listFileID, (item) => item !== id);
          if (success >= total) {
            this.theLastFileImport = theFile;

            // show dialog move to view export file
            // this.dialogMove2ExportView = true;
          }
          break;
        }
      }
      // if listFileID = 0, clear interval
      if (this.listFileID.length === 0) {
        this.loading = false;
        // if stop
        return false;
      }
      // if continue
      return true;
    },

    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
